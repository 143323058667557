@import "constants";

body {
  overflow-x: hidden;
}

*::selection {
  background-color: $pink;
  color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $header-font;
  font-weight: 400;
}

p {
  font-family: $body-font;
}

#main-content-cont.concealed {
  visibility: hidden;
}

.constrained-section {
  position: relative;
  max-width: $main-content-width;
  margin: 0 auto;
}

.body-line {
  position: absolute;
  z-index: -1;
  background-color: $gold;
}

.body-line-box {
  position: absolute;
  z-index: -1;
}

.feature-title {
  font-family: $header-font;
  font-weight: normal;
  font-size: 64px;
  line-height: 80px;
  color: white;
}

.feature-description {
  margin-top: 10px;
  font-family: $body-font;
  font-weight: normal;
  font-size: 24px;
  line-height: 34px;
  color: white;
  max-width: 500px;
}

@media (max-width: $mobile-screen-width) {
  .feature-title {
    font-size: 24px;
    line-height: 30px;
  }

  .feature-description {
    font-size: 14px;
    line-height: 22px;
    max-width: unset !important;
  }
}
