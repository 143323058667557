body {
  overflow-x: hidden;
}

::selection {
  color: #fff;
  background-color: #9e0932;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Righteous, sans-serif;
  font-weight: 400;
}

p {
  font-family: Oxygen, sans-serif;
}

#main-content-cont.concealed {
  visibility: hidden;
}

.constrained-section {
  max-width: 1080px;
  margin: 0 auto;
  position: relative;
}

.body-line {
  z-index: -1;
  background-color: #9e7509;
  position: absolute;
}

.body-line-box {
  z-index: -1;
  position: absolute;
}

.feature-title {
  color: #fff;
  font-family: Righteous, sans-serif;
  font-size: 64px;
  font-weight: normal;
  line-height: 80px;
}

.feature-description {
  color: #fff;
  max-width: 500px;
  margin-top: 10px;
  font-family: Oxygen, sans-serif;
  font-size: 24px;
  font-weight: normal;
  line-height: 34px;
}

@media (max-width: 1140px) {
  .feature-title {
    font-size: 24px;
    line-height: 30px;
  }

  .feature-description {
    font-size: 14px;
    line-height: 22px;
    max-width: unset !important;
  }
}

/*# sourceMappingURL=index.f0e649ae.css.map */
